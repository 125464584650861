@import 'styles/config';

.login {
  @media (min-width: $min-desktop) {
    border-top: 8px solid var(--color-primary);
  }
  &__contentWrap {
    padding: 16px;
  }

  &__contentInner {
    padding-top: var(--gutter);
  }

  &__contentTop {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-right: var(--gutter);
  }

  @media (max-width: $max-tablet) {
    &__imageWrap {
      display: none;
    }
  }

  @media (min-width: $min-tablet) {
    display: flex;
    justify-content: flex-end;

    width: 100%;
    min-height: 100vh;

    &__contentWrap {
      display: flex;
      flex-direction: column;

      width: 50%;
      max-width: div($page-limit, 2);
    }

    &__content {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;

      padding: $gutter;

      width: 100%;
    }

    &__contentInner {
      max-width: 440px;
    }

    &__imageWrap {
      position: relative;

      width: 50%;
    }

    &__image {
      @include image-fit;
    }
  }
}
