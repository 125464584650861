@import 'styles/config';

.loginTabs {
  display: flex;

  position: relative;

  margin-bottom: scale-to-em(64px);
  margin-top: scale-to-em(80px);

  width: 100%;

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    z-index: -1;

    width: 100%;
    height: 2px;

    background-color: $color-secondary-100;
  }

  &__tab {
    position: relative;

    padding-right: scale-to-em(16px);
    padding-left: scale-to-em(16px);
    padding-bottom: scale-to-em(16px);

    border: none;
    outline: none;

    white-space: nowrap;

    background: none;
    color: $color-secondary-400;
    &:not(:last-child) {
      margin-right: scale-to-em(16px);
    }

    &:before {
      content: '';

      position: absolute _ _ 0 0;

      height: 2px;
      min-width: 0;

      background: var(--color-primary-ui, $color-primary);

      transition: all 300ms ease-in-out;
    }

    &::after {
      content: attr(title);

      display: block;

      height: 1px;
      overflow: hidden;

      visibility: hidden;

      font-weight: bold;

      color: transparent;
    }

    &:hover,
    &:focus {
      cursor: pointer;

      color: var(--color-primary-ui, $color-primary);

      &:before {
        min-width: 100%;
      }
    }

    &[aria-selected='true'] {
      border-bottom: 2px solid var(--color-primary);

      font-weight: bold;

      color: var(--color-primary-ui, $color-primary);

      &:before {
        width: 100%;
      }
    }
  }
}
