@import 'styles/config';

.textInput {
  display: flex;
  flex-direction: column;

  &.login {
    align-self: flex-start;
    flex: none;

    position: relative;
    z-index: 0;

    width: 100%;
  }

  &__inputContainer {
    display: flex;
    flex-direction: row;

    gap: calc(var(--gutter) / 2);
  }

  &__input {
    @include input-defaults;
    @include form-states;

    &__focus {
      @include focus-state;
    }

    &.login {
      @include login-defaults;
      @include login-focus;

      &:placeholder-shown {
        padding-top: 0;
      }

      // if there is a label and we are using floatLabels (which is default)
      .hasLabel:not(.hideFloatLabel) &:not(:placeholder-shown) {
        padding-top: 12px;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 1em;
    right: $input-padding-mobile;

    width: 19px;
    height: 19px;

    pointer-events: none;

    color: $color-primary;

    transform: translateY(-50%);

    @media (min-width: $min-tablet) {
      right: $input-padding;
    }
  }

  &__labelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    width: 100%;
  }

  &__label {
    flex: 1;

    padding-bottom: 10px;

    width: 100%;

    font-size: $font-size-xs;

    &.login {
      @include login-label;
      color: $color-secondary-200;
    }
  }

  &__input:placeholder-shown ~ &__label {
    pointer-events: none;

    opacity: 0;

    transition: none;
  }

  &__spacer {
    @include spacer;
  }
}
