@import 'styles/config';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding-top: 0.5em;
  padding-bottom: 0.5em;

  width: 100%;
}

.message {
  @include responsive-font($font-size-xs-min, $font-size-xs);

  line-height: $line-height;

  color: $color-red;
}
