@import 'styles/config';

.formLayout {
  &__item {
    margin-bottom: $gutter;

    &:last-child {
      margin-bottom: 0;
    }

    > button {
      margin-top: 0;
    }
  }
}
