@import 'styles/config';

.tooltip {
  &__icon {
    width: 14px;
    height: 14px;

    cursor: help;

    color: $color-secondary-200;
  }
}
